var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorNotification, QueryParameters, } from '@ewego/portal-frontend-shared';
import { render } from 'react-dom';
import { BehaviorSubject } from 'rxjs';
import { registerApplication, start, unregisterApplication } from 'single-spa';
export var showError = function () {
    render(ErrorNotification({}), document.getElementById('root-errors'));
};
var getFlatPermissions = function (accessToken) {
    var _a, _b, _c, _d, _e;
    var userPermissions = (_b = (_a = accessToken === null || accessToken === void 0 ? void 0 : accessToken.access_grant) === null || _a === void 0 ? void 0 : _a.userPermissions) !== null && _b !== void 0 ? _b : [];
    var requestedTenantPermissions = (_e = (_d = (_c = accessToken === null || accessToken === void 0 ? void 0 : accessToken.access_grant) === null || _c === void 0 ? void 0 : _c.tenant) === null || _d === void 0 ? void 0 : _d.permissions) !== null && _e !== void 0 ? _e : [];
    return __spreadArray(__spreadArray([], userPermissions, true), requestedTenantPermissions, true);
};
var isUserAllowedToAccessModule = function (moduleInfo, flatUserPermissions) {
    var _a, _b, _c;
    try {
        var noPermissionsSpecified = !((_a = moduleInfo === null || moduleInfo === void 0 ? void 0 : moduleInfo.moduleAccess) === null || _a === void 0 ? void 0 : _a.permissions) || ((_c = (_b = moduleInfo === null || moduleInfo === void 0 ? void 0 : moduleInfo.moduleAccess) === null || _b === void 0 ? void 0 : _b.permissions) === null || _c === void 0 ? void 0 : _c.length) === 0;
        if (moduleInfo.moduleAccess.noExtraAccessRequired && noPermissionsSpecified) {
            return true;
        }
        var moduleAccess = moduleInfo.moduleAccess;
        if (moduleAccess) {
            var modulePermissions = moduleAccess.permissions;
            // Check whether the moduleAccess was defined properly for a set of required permissions
            if (flatUserPermissions &&
                modulePermissions &&
                moduleAccess.noExtraAccessRequired === false &&
                modulePermissions.length > 0) {
                // Check whether a user has ALL required permission for at least a single conjunction (PA1 && PA2 && ... && PAN) defined in the module.
                // DNF like => (PA1 && ... && PN1) || ... || (PM1 && ... && PMN)
                return modulePermissions.some(function (conjunctions) {
                    return conjunctions.every(function (value) { return flatUserPermissions.includes(value); });
                });
            }
        }
    }
    catch (e) {
        console.error(e);
    }
    return false;
};
var defaultApolloClientOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};
var RootPortalApplication = /** @class */ (function () {
    function RootPortalApplication(props) {
        this.props = props;
        this.logoPath = "".concat(process.env.ROOT_ASSET_BASE, "/domains/portal.ewe-go.de/logo.svg");
        this.modules = [];
        this.applicationsRegistered = [];
        this.modulesSubject = new BehaviorSubject([]);
        this.workers = new BehaviorSubject([]);
        this.authToken = new BehaviorSubject(null);
        this.resourceAccessToken = new BehaviorSubject(null);
        this.identity = new BehaviorSubject(null);
        this.identityProvider = new BehaviorSubject(null);
        this.cognitoUserInfo = new BehaviorSubject(null);
        this.apolloClient = new ApolloClient({
            link: new HttpLink({ uri: this.props.portalApiBase }),
            cache: new InMemoryCache(),
            defaultOptions: defaultApolloClientOptions,
        });
        this.appConfig = new BehaviorSubject(props.appConfig);
    }
    RootPortalApplication.prototype.getGoogleMapsApiKey = function () {
        return this.props.googleMapsApiKey;
    };
    RootPortalApplication.prototype.getIdentityProvider = function () {
        return this.identityProvider;
    };
    RootPortalApplication.prototype.setIdentityProvider = function (provider) {
        this.identityProvider.next(provider);
    };
    RootPortalApplication.prototype.init = function () {
        return __awaiter(this, void 0, Promise, function () {
            var dashboardEntryPoint;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dashboardEntryPoint = this.props.dashboardEntryPoint;
                        registerApplication({
                            name: 'dashboard',
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            app: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, System.import(dashboardEntryPoint)];
                            }); }); },
                            activeWhen: ['/'],
                            customProps: __assign({}, this.getModuleProps()),
                        });
                        this.applicationsRegistered.push('dashboard');
                        start({
                            urlRerouteOnly: true,
                        });
                        return [4 /*yield*/, this.fetchIdentity()];
                    case 1:
                        _a.sent();
                        this.authToken.subscribe(function (currentAuthToken) { return __awaiter(_this, void 0, void 0, function () {
                            var resourceTokenRequest, resourceAccessTokenResponse, resourceAccessToken, flatUserPermissions, error_1;
                            var _a, _b, _c, _d, _e;
                            return __generator(this, function (_f) {
                                switch (_f.label) {
                                    case 0:
                                        if (!currentAuthToken) {
                                            return [2 /*return*/];
                                        }
                                        resourceTokenRequest = {
                                            tenantId: 'ego',
                                        };
                                        _f.label = 1;
                                    case 1:
                                        _f.trys.push([1, 4, , 9]);
                                        return [4 /*yield*/, this.props.authApi.getResourceAccessToken(resourceTokenRequest, {
                                                headers: {
                                                    Authorization: "Bearer ".concat(currentAuthToken),
                                                },
                                            })];
                                    case 2:
                                        resourceAccessTokenResponse = (_f.sent()).data;
                                        resourceAccessToken = resourceAccessTokenResponse.access_token;
                                        this.props.permissionsApiConfiguration.baseOptions = __assign(__assign({}, this.props.permissionsApiConfiguration.baseOptions), { headers: __assign(__assign({}, (_b = (_a = this.props.permissionsApiConfiguration) === null || _a === void 0 ? void 0 : _a.baseOptions) === null || _b === void 0 ? void 0 : _b.headers), { Authorization: "Bearer ".concat(resourceAccessToken) }) });
                                        flatUserPermissions = getFlatPermissions(resourceAccessTokenResponse);
                                        // request modules (MFs) with RAT
                                        return [4 /*yield*/, this.initModules(resourceAccessToken, flatUserPermissions)];
                                    case 3:
                                        // request modules (MFs) with RAT
                                        _f.sent();
                                        // set RAT
                                        this.resourceAccessToken.next(resourceAccessTokenResponse);
                                        return [3 /*break*/, 9];
                                    case 4:
                                        error_1 = _f.sent();
                                        if (!(((_c = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _c === void 0 ? void 0 : _c.status) === 400 || ((_d = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _d === void 0 ? void 0 : _d.status) === 401)) return [3 /*break*/, 5];
                                        localStorage.clear();
                                        location.reload();
                                        return [3 /*break*/, 8];
                                    case 5:
                                        if (!(((_e = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _e === void 0 ? void 0 : _e.status) === 404)) return [3 /*break*/, 7];
                                        // request modules (MFs) with just UAT
                                        return [4 /*yield*/, this.initModules(currentAuthToken, [])];
                                    case 6:
                                        // request modules (MFs) with just UAT
                                        _f.sent();
                                        return [3 /*break*/, 8];
                                    case 7:
                                        showError();
                                        throw error_1;
                                    case 8: return [3 /*break*/, 9];
                                    case 9: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    RootPortalApplication.prototype.initModules = function (accessToken, flatUserPermissions) {
        return __awaiter(this, void 0, void 0, function () {
            var modulesApi, httpLink, authLink, modulesRequestHeaders, modules, workers, _loop_1, this_1, _i, _a, moduleInfo;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        modulesApi = this.props.modulesApi;
                        httpLink = new HttpLink({ uri: this.props.portalApiBase });
                        authLink = setContext(function (_, _a) {
                            var headers = _a.headers;
                            return {
                                headers: __assign(__assign({}, headers), { authorization: accessToken ? "Bearer ".concat(accessToken) : '' }),
                            };
                        });
                        this.apolloClient.setLink(authLink.concat(httpLink));
                        modulesRequestHeaders = {
                            headers: {
                                Authorization: "Bearer ".concat(accessToken),
                            },
                        };
                        return [4 /*yield*/, modulesApi.getAllModules(modulesRequestHeaders)];
                    case 1:
                        modules = (_b.sent()).data;
                        this.modules = modules;
                        this.modulesSubject.next(modules);
                        workers = this.workers.getValue();
                        _loop_1 = function (moduleInfo) {
                            var workerFactory, worker, moduleFactory_1, e_1, registeredApplicationIndex, registeredApplicationIndex, workerIndex;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (!isUserAllowedToAccessModule(moduleInfo, flatUserPermissions)) return [3 /*break*/, 7];
                                        if (!(moduleInfo.worker && workers.findIndex(function (worker) { return worker.id === moduleInfo.id; }) === -1)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, System.import(moduleInfo.worker)];
                                    case 1:
                                        workerFactory = (_c.sent()).default;
                                        worker = workerFactory({
                                            application: this_1,
                                        });
                                        workers.push({
                                            id: moduleInfo.id,
                                            worker: worker,
                                        });
                                        _c.label = 2;
                                    case 2:
                                        if (!!this_1.applicationsRegistered.includes(moduleInfo.id)) return [3 /*break*/, 6];
                                        _c.label = 3;
                                    case 3:
                                        _c.trys.push([3, 5, , 6]);
                                        this_1.applicationsRegistered.push(moduleInfo.id);
                                        return [4 /*yield*/, System.import(moduleInfo.path)];
                                    case 4:
                                        moduleFactory_1 = (_c.sent()).default;
                                        registerApplication({
                                            name: moduleInfo.id,
                                            app: function () { return __awaiter(_this, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    return [2 /*return*/, moduleFactory_1(moduleInfo.id)];
                                                });
                                            }); },
                                            activeWhen: moduleInfo.activeWhen,
                                            customProps: __assign({}, this_1.getModuleProps()),
                                        });
                                        return [3 /*break*/, 6];
                                    case 5:
                                        e_1 = _c.sent();
                                        registeredApplicationIndex = this_1.applicationsRegistered.indexOf(moduleInfo.id);
                                        this_1.applicationsRegistered.splice(registeredApplicationIndex, 1);
                                        throw e_1;
                                    case 6: return [3 /*break*/, 9];
                                    case 7:
                                        if (!this_1.applicationsRegistered.includes(moduleInfo.id)) return [3 /*break*/, 9];
                                        return [4 /*yield*/, unregisterApplication(moduleInfo.id)];
                                    case 8:
                                        _c.sent();
                                        registeredApplicationIndex = this_1.applicationsRegistered.indexOf(moduleInfo.id);
                                        workerIndex = workers.findIndex(function (worker) { return worker.id === moduleInfo.id; });
                                        workers.splice(workerIndex, 1);
                                        this_1.applicationsRegistered.splice(registeredApplicationIndex, 1);
                                        _c.label = 9;
                                    case 9: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, _a = this.modules;
                        _b.label = 2;
                    case 2:
                        if (!(_i < _a.length)) return [3 /*break*/, 5];
                        moduleInfo = _a[_i];
                        return [5 /*yield**/, _loop_1(moduleInfo)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        this.workers.next(workers);
                        return [2 /*return*/];
                }
            });
        });
    };
    RootPortalApplication.prototype.getModuleProps = function () {
        return {
            application: this,
        };
    };
    RootPortalApplication.prototype.fetchIdentity = function () {
        return __awaiter(this, void 0, Promise, function () {
            var domainName, queryParams, domain, identityInfo;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        domainName = window.location.host.startsWith('localhost') ? process.env.DOMAIN_NAME : window.location.host;
                        // Overwrite the domainName to query the identity info against, when a domain query parameter is set
                        if (window.URLSearchParams) {
                            queryParams = new window.URLSearchParams(window.location.search);
                            if (queryParams.has(QueryParameters.DOMAIN)) {
                                domain = queryParams.get(QueryParameters.DOMAIN);
                                if (domain) {
                                    domainName = domain;
                                }
                            }
                        }
                        return [4 /*yield*/, this.props.identityApi.getIdentityByDomainName(domainName)];
                    case 1:
                        identityInfo = (_d.sent()).data;
                        if (identityInfo) {
                            this.props.permissionsApiConfiguration.baseOptions = __assign(__assign({}, (_a = this.props.permissionsApiConfiguration) === null || _a === void 0 ? void 0 : _a.baseOptions), { headers: __assign(__assign({}, (_c = (_b = this.props.permissionsApiConfiguration) === null || _b === void 0 ? void 0 : _b.baseOptions) === null || _c === void 0 ? void 0 : _c.headers), { Identity: identityInfo.id }) });
                            this.identity.next(identityInfo);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RootPortalApplication.prototype.getIdentity = function () {
        return this.identity.asObservable();
    };
    RootPortalApplication.prototype.getUsersApi = function () {
        return this.props.usersApi;
    };
    RootPortalApplication.prototype.setAuthToken = function (token) {
        this.authToken.next(token);
    };
    RootPortalApplication.prototype.setCognitoUserInfo = function (cognitoUserInfo) {
        this.cognitoUserInfo.next(cognitoUserInfo);
    };
    return RootPortalApplication;
}());
export { RootPortalApplication };
