"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatorRules = void 0;
exports.validatorRules = {
    companyName: {
        max: 27,
        min: 2,
    },
    firstName: {
        max: 27,
        min: 2,
    },
    houseNumber: {
        max: 12,
    },
    lastName: {
        max: 27,
        min: 2,
    },
};
