var _a, _b, _c, _d, _e;
import { AuthApi, IdentitiesApi, Configuration as PermissionsApiConfiguration, UsersApi, } from '@ewego/global-permissions-api';
import { ModulesApi, PortalApiConfiguration, ROOT_APP_CONFIG_ID } from '@ewego/portal-shared-types';
import { RootPortalApplication, showError } from './application';
var injectedConfig = {};
var injectedScript = document.getElementById(ROOT_APP_CONFIG_ID);
if (injectedScript) {
    try {
        injectedConfig = JSON.parse(injectedScript.textContent);
    }
    catch (e) {
        console.error("Unable to parse injected config due to", e);
    }
}
var portalApiBase = (_a = process.env.API_BASE) !== null && _a !== void 0 ? _a : injectedConfig.API_BASE;
var portalModulesApiBase = (_b = process.env.MODULES_API_BASE) !== null && _b !== void 0 ? _b : injectedConfig.MODULES_API_BASE;
var permissionsApiBase = (_c = process.env.PERMISSIONS_BASE) !== null && _c !== void 0 ? _c : injectedConfig.PERMISSIONS_BASE;
var googleMapsApiKey = (_d = process.env.GOOGLE_MAPS_API_KEY) !== null && _d !== void 0 ? _d : injectedConfig.GOOGLE_MAPS_API_KEY;
var portalModulesApiConfiguration = new PortalApiConfiguration({
    basePath: (_e = (portalModulesApiBase === '' ? "".concat(window.location.origin, "/api") : portalModulesApiBase)) !== null && _e !== void 0 ? _e : portalModulesApiBase,
});
var permissionsApiConfiguration = new PermissionsApiConfiguration({
    basePath: permissionsApiBase,
});
var identityApi = new IdentitiesApi(permissionsApiConfiguration);
var usersApi = new UsersApi(permissionsApiConfiguration);
var authApi = new AuthApi(permissionsApiConfiguration);
var rootApp = new RootPortalApplication({
    authApi: authApi,
    dashboardEntryPoint: process.env.DASHBOARD_URL,
    identityApi: identityApi,
    modulesApi: new ModulesApi(portalModulesApiConfiguration),
    permissionsApiConfiguration: permissionsApiConfiguration,
    portalApiBase: portalApiBase,
    usersApi: usersApi,
    googleMapsApiKey: googleMapsApiKey,
    appConfig: injectedConfig,
});
rootApp
    .init()
    .then(function () {
    console.debug('Portal loaded', rootApp);
})
    .catch(function (error) {
    showError();
    throw error;
});
